$john-shortland-online-black:           #000000;
$john-shortland-online-black-80pc:      rgba($john-shortland-online-black, .8);

$john-shortland-online-light-grey:      #e9e9e9;
$john-shortland-online-light-grey-80pc:      rgba($john-shortland-online-light-grey, .8);

$john-shortland-online-light-grey-2:    #efefef;
$john-shortland-online-light-grey-2-80pc:      rgba($john-shortland-online-light-grey-2, .8);

$john-shortland-online-dark-green:      #436303;
$john-shortland-online-dark-green-80pc: rgba($john-shortland-online-dark-green, .8);

$john-shortland-online-green:           #79b400;
$john-shortland-online-green-80pc:      rgba($john-shortland-online-green, .8);

$john-shortland-online-yellow:          #ffa500;
$john-shortland-online-yellow-80pc:      rgba($john-shortland-online-yellow, .8);

$john-shortland-online-red:             #ff0000;
$john-shortland-online-red-80pc:      rgba($john-shortland-online-red, .8);
