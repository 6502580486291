@import '/john-shortland-online-web/variables';

body {
  background-color: $john-shortland-online-black;
  height: 100%;
  overflow: auto;
}

.page {
  background: rgba($john-shortland-online-black, 0.8);
  background: -webkit-linear-gradient(
    90deg,
    $john-shortland-online-black-80pc 10%,
    $john-shortland-online-dark-green 50%,
    $john-shortland-online-dark-green 70%
  );
  background: linear-gradient(
    90deg,
    $john-shortland-online-black-80pc 10%,
    rgba($john-shortland-online-dark-green, 0.92) 50%,
    rgba($john-shortland-online-dark-green, 0.95) 70%
  );
  -moz-box-shadow: 0 0 20px $john-shortland-online-black;
  -webkit-box-shadow: 0 0 20px $john-shortland-online-black;
  box-shadow: 0 0 20px $john-shortland-online-black;
  color: $john-shortland-online-light-grey;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px !important;
  min-width: 320px !important;
  padding: 0 30px;
  position: relative;
  width: 100%;
}

a {
  color: $john-shortland-online-green !important;
  text-decoration: none !important;
  &:hover,
  &:focus,
  &.active {
    color: $john-shortland-online-yellow !important;
  }
}

.green-text {
  color: $john-shortland-online-green;
}

.yellow-text {
  color: $john-shortland-online-yellow;
}

.jsdc-anchor {
  display: block;
  position: relative;
  top: -7rem;
  visibility: hidden;
}

.important-box {
  box-shadow: 0 0 18px $john-shortland-online-light-grey-2;
  color: $john-shortland-online-green;
  padding: 5px 10px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .page {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
